img{
  padding: 10%;
  width: 80%;
  @include media-query($on-laptop){
    padding: 0%;
    width: 100%
  }
}

img#profile{
  float: right;
  padding: 5%;
  width: 40%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  @include media-query($on-laptop){
    float: inherit;
    width: inherit;
    padding: inherit;
    padding-bottom: $spacing-unit;
  }
}

.post-footer{
  border-top: 1px solid #e8e8e8;
}

#mc_embed_signup_scroll label{
  font-weight: bold;
  display: block;
  margin-top: 1em;
  margin-bottom: .5em;
}

.input-text{
  border: 1px solid #424242;
  border-radius: 20px;
  padding: .2em;
  padding-left: .6em;
  padding-bottom: .3em;
  float: left;
  margin: .2em;
}

.submit-newsletter{
  border: 1px solid #424242;
  border-radius: 20px;
  padding: .2em;
  display: inline-block;
  margin: .2em;
}

.input-text > input {
  border: none;
  background-color: transparent;
}
#mce-EMAIL, #mce-EMAIL:focus{
  outline: none;
  display: inline-block;
  padding: .4em;
  width: 100%;
  border: none;
  background-color: transparent;
}

#mc-embedded-subscribe{
  float: left;
  border-radius: 20px;
  background-color: transparent;
  border: none;
  padding: .4em;
  margin: 0;
}